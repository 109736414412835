import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "../../styles/dashboard-style.css";
import Header from "./Header";
import { color } from "@mui/system";
import StaticFooter from "./StaticFooter";
const TermsOfUse = () => {
  return (
    <div className="dashboardContent error-template">
      <Helmet>
        <title>Términos de Uso | ASRC iGlebe</title>
      </Helmet>
      <div>
        <Header />
      </div>
      <div>
        <div className="row ">
          <div className="col-lg-3"></div>
          <div className="col-lg-6 region region-content1 ">
            <div
              id="block-satp-user-content"
              className="block block-system block-system-main-block"
            >
              <div className="content welcomeContent1" id="heading">
                Términos de Uso
              </div>
            </div>
            <div
              id="block-satp-user-content"
              className="block block-system block-system-main-block mt-3"
            >
              <div className="content">
                <p>
                  Este sitio en https://iglebe.asrc.edu.au/app/ (Sitio) ofrece
                  un programa de terapia cognitivo-conductual (TCC) para adultos
                  que tartamudean. El programa está diseñado específicamente
                  para enseñar a los adultos que tartamudean a manejar la
                  ansiedad social (Programa).
                </p>

                <p>
                  El Sitio es proporcionado por la Universidad de Tecnología de
                  Sydney (UTS) (ABN 77 257 686 961) (“la Universidad”,
                  “nosotros” o “nuestro”) a través de su Centro de Investigación
                  sobre Tartamudez en Australia. Los términos y condiciones
                  establecidos en esta página (Términos) rigen su acceso y uso
                  del Sitio.
                </p>

                <p>
                  Si navega por el Sitio o procede a acceder al Programa, se
                  considera que ha aceptado los Términos y acepta cumplir en
                  todo momento con todas las leyes aplicables en relación con el
                  uso del Sitio, así como con nuestros estatutos, normas,
                  políticas y procedimientos, y que es responsable de informarse
                  sobre los requisitos que le correspondan según dichos
                  estatutos, normas, políticas y procedimientos disponibles
                  aquí:{" "}
                  <a href="https://www.uts.edu.au/about/uts-governance/policies">
                    https://www.uts.edu.au/about/uts-governance/policies
                  </a>
                  <br />
                  UTS puede cambiar el contenido del Programa, la información en
                  el Sitio, estos Términos o nuestra Política de Privacidad a
                  nuestra discreción y sin previo aviso, sujeto a la ley. Al
                  continuar utilizando el Programa, acepta los Términos tal como
                  se aplican de vez en cuando.
                </p>
              </div>
            </div>

            <div
              id="block-satp-user-content"
              className="block block-system block-system-main-block"
            >
              <div className="content welcomeContent1">
                <h3 id="para">Programa</h3>
              </div>
            </div>
            <div
              id="block-satp-user-content"
              className="block block-system block-system-main-block mt-3"
            >
              <div className="content">
                <p>
                  El Programa ofrece TCC para la ansiedad social a adultos que
                  tartamudean. Los procedimientos utilizados dentro del Programa
                  se basan en técnicas bien establecidas de terapia
                  cognitivo-conductual. El programa es gratuito y está
                  disponible para adultos que tartamudean y que pueden leer y
                  escribir en inglés.
                </p>
                <p>
                  El Programa ha sido desarrollado por investigadores del Centro
                  de Investigación sobre Tartamudez de Australia, Universidad de
                  Tecnología. El desarrollo inicial del programa fue apoyado por
                  la Universidad de Sydney.
                </p>
              </div>
            </div>

            <div
              id="block-satp-user-content"
              className="block block-system block-system-main-block"
            >
              <div className="content welcomeContent1">
                <h3 id="para">Contenido</h3>
              </div>
            </div>
            <div
              id="block-satp-user-content"
              className="block block-system block-system-main-block mt-3"
            >
              <div className="content">
                <p>
                  El Programa está adaptado para cada usuario en función de sus
                  respuestas a una serie de cuestionarios administrados al
                  inicio del Programa. Aunque el Programa no implica contacto
                  directo con un clínico, utiliza las voces y los rostros de dos
                  psicólogos clínicos—un hombre y una mujer—para guiar al
                  usuario a través del Programa.
                </p>
                <p>
                  El Programa está diseñado para ser un paquete de TCC
                  individualizado para cada usuario. El Programa tiene siete
                  secciones y las secciones deben completarse en orden
                  secuencial. Cada sección puede incluir texto en pantalla y
                  gráficos, audio incrustado, tareas prácticas y materiales. El
                  Programa está diseñado para que los usuarios inicien sesión
                  regularmente, completen tareas de aprendizaje en línea y
                  realicen tareas mientras están fuera del Programa durante sus
                  situaciones diarias.
                </p>

                <p>
                  El usuario recibe correos electrónicos automáticos a lo largo
                  del Programa. Los correos electrónicos se envían cuando el
                  usuario completa una sección. También se envían correos
                  electrónicos si el usuario no ha iniciado sesión en el
                  Programa durante 3, 7, 10, 14, 21 o 21 días. Los correos
                  electrónicos recuerdan a los usuarios que tienen 5 meses para
                  completar el Programa.
                </p>
              </div>
            </div>

            <div
              id="block-satp-user-content"
              className="block block-system block-system-main-block"
            >
              <div className="content welcomeContent1">
                <h3 id="para">Información Importante</h3>
              </div>
            </div>
            <div
              id="block-satp-user-content"
              className="block block-system block-system-main-block mt-3"
            >
              <div className="content">
                <p>
                  El propósito del Programa es proporcionar un paquete de TCC
                  individualizado para adultos que tartamudean.
                </p>
                <p>
                  La información contenida en o a través del Sitio es para su
                  información general sobre la ansiedad social y para
                  introducirle a patrones de pensamiento comunes que pueden
                  resultar en ansiedad. La información proporcionada durante el
                  programa puede ser utilizada para ayudarle a manejar la
                  ansiedad social, pero no está destinada a ser utilizada como
                  asesoramiento médico. La información proporcionada a través
                  del Sitio no sustituye el asesoramiento profesional
                  independiente y no debe utilizarse como alternativa al
                  asesoramiento médico o atención profesional de salud.
                </p>

                <p>
                  Este Sitio no está diseñado para diagnosticar o tratar la
                  ansiedad u otras condiciones de salud mental. El diagnóstico y
                  tratamiento de ansiedad clínica y depresión requiere de un
                  médico o profesional de salud mental calificado. Las personas
                  que buscan un diagnóstico o tratamiento de depresión, ansiedad
                  u otros trastornos de salud mental deben consultar a un médico
                  o profesional de salud mental. Los cuestionarios en el
                  Programa no están diseñados para proporcionar un diagnóstico.
                </p>

                <p>
                  Al usar este Sitio y continuar accediendo al Programa, usted
                  reconoce y acepta que ninguna información o consejo
                  proporcionado en o a través de este Sitio constituye
                  asesoramiento médico, psicológico o de atención de salud y
                  reconoce que debe consultar a un profesional apropiado para
                  obtener asesoramiento específico adaptado a su situación.
                  También debe evitar ignorar el asesoramiento profesional o
                  retrasar su búsqueda debido a cualquier información contenida
                  en el Programa, incluidos los resultados proporcionados (por
                  ejemplo, como parte de los cuestionarios o planes de
                  tratamiento).
                </p>
              </div>
            </div>

            <div
              id="block-satp-user-content"
              className="block block-system block-system-main-block"
            >
              <div className="content welcomeContent1">
                <h3 id="para">Supervisión</h3>
              </div>
            </div>

            <div
              id="block-satp-user-content"
              className="block block-system block-system-main-block mt-3"
            >
              <div cclassName="content">
                <p>Usted reconoce y acepta que:</p>
                <p>
                  <ul>
                    <li>
                      Su participación en el Programa no será monitoreada ni
                      revisada por un profesional de salud.
                    </li>
                    <li>
                      No estamos obligados a hacer un seguimiento con usted en
                      relación con su atención o salud mental.
                    </li>
                  </ul>
                </p>
              </div>
            </div>

            <div
              id="block-satp-user-content"
              className="block block-system block-system-main-block"
            >
              <div className="content welcomeContent1">
                <h3 id="para">Responsabilidad y Garantías</h3>
              </div>
            </div>

            <div
              id="block-satp-user-content"
              className="block block-system block-system-main-block mt-3"
            >
              <div className="content">
                <p>
                  La Universidad no ofrece garantías ni representaciones sobre
                  la calidad, actualidad, contenido, integridad, precisión o
                  adecuación de la información en y disponible a través de este
                  Sitio, o la idoneidad y/o disponibilidad del Programa,
                  incluido el plan de manejo sugerido. El Programa se le
                  proporciona "tal como está" y "según esté disponible".
                </p>
                <p>
                  Usted acepta que su participación en el Programa es bajo su
                  propio riesgo. No garantizamos que:
                </p>
                <p>
                  <ul>
                    <li>
                      El Programa o su contenido satisfagan sus necesidades o
                      requisitos específicos.
                    </li>
                    <li>
                      El Programa o su contenido serán ininterrumpidos,
                      puntuales, seguros o libres de errores.
                    </li>
                    <li>
                      Los resultados (incluidos los resultados de cualquier
                      prueba o evaluación) que se obtengan del uso del Programa
                      serán precisos o fiables.
                    </li>
                    <li>
                      La calidad de cualquier producto, servicio, información u
                      otro material que obtenga a través del Programa cumpla con
                      sus expectativas.
                    </li>
                  </ul>
                </p>
                <p>
                  Aunque el Programa está sujeto a los procesos de aseguramiento
                  de calidad de la Universidad, también está sujeto a la
                  investigación continua, experiencia clínica, diferencias
                  razonables en las opiniones entre autoridades, aspectos únicos
                  de situaciones individuales y la posibilidad de error humano.
                </p>
                <p>
                  Aunque proporcionamos información general de salud, las
                  circunstancias de cada individuo pueden diferir. Debe ejercer
                  su propio juicio con respecto a su participación en el
                  Programa y evaluar cuidadosamente la actualidad, integridad,
                  precisión, fiabilidad, idoneidad y relevancia de la
                  información disponible.
                </p>
              </div>
            </div>

            <div
              id="block-satp-user-content"
              className="block block-system block-system-main-block"
            >
              <div className="content welcomeContent1">
                <h3 id="para">Elegibilidad</h3>
              </div>
            </div>

            <div
              id="block-satp-user-content"
              className="block block-system block-system-main-block mt-3"
            >
              <div className="content">
                <p>
                  El Programa ha sido diseñado para adultos que tartamudean.
                </p>
              </div>
            </div>

            <div
              id="block-satp-user-content"
              className="block block-system block-system-main-block"
            >
              <div className="content welcomeContent1">
                <h3 id="para">Derechos de Propiedad Intelectual</h3>
              </div>
            </div>

            <div
              id="block-satp-user-content"
              className="block block-system block-system-main-block mt-3"
            >
              <div className="content">
                <p>
                  Todos los derechos de propiedad intelectual del Programa
                  (incluido el software, diseño, texto, datos, iconos,
                  logotipos, grabaciones de sonido y gráficos incluidos en el
                  Programa) son propiedad de la Universidad de Tecnología de
                  Sydney.
                </p>
              </div>
            </div>

            <div
              id="block-satp-user-content"
              className="block block-system block-system-main-block"
            >
              <div className="content welcomeContent1">
                <h3 id="para">Reconocimiento</h3>
              </div>
            </div>

            <div
              id="block-satp-user-content"
              className="block block-system block-system-main-block mt-3"
            >
              <div className="content">
                <p>
                  La Universidad de Tecnología de Sydney reconoce que la
                  Universidad de Sydney estuvo involucrada en el desarrollo
                  inicial de este sitio web y algunos de los materiales
                  utilizados en el Programa.
                </p>
                <p>
                  La información o materiales obtenidos de un tercero pueden
                  estar sujetos a derechos de autor propiedad de ese tercero.
                </p>
              </div>
            </div>

            <div
              id="block-satp-user-content"
              className="block block-system block-system-main-block"
            >
              <div className="content welcomeContent1">
                <h3 id="para">Requisitos del Sistema</h3>
              </div>
            </div>

            <div
              id="block-satp-user-content"
              className="block block-system block-system-main-block mt-3"
            >
              <div className="content">
                <p>
                  Algunos servicios disponibles a través del Sitio requerirán
                  que su computadora, teléfono, acceso a Internet, dispositivo y
                  equipo asociado cumplan con ciertos niveles técnicos
                  (Requisitos del Sistema) para recibir los servicios. UTS no se
                  hace responsable de los problemas asociados con o derivados de
                  su incapacidad para acceder o recibir los servicios debido a
                  que su equipo o velocidad de Internet no cumpla con los
                  Requisitos del Sistema.
                </p>
              </div>
            </div>

            <div
              id="block-satp-user-content"
              className="block block-system block-system-main-block"
            >
              <div className="content welcomeContent1">
                <h3 id="para">General</h3>
              </div>
            </div>

            <div
              id="block-satp-user-content"
              className="block block-system block-system-main-block mt-3"
            >
              <div className="content">
                <p>
                  Este acuerdo se rige por las leyes de Nueva Gales del Sur,
                  Australia. Cada parte se somete a la jurisdicción no exclusiva
                  de los tribunales de ese lugar.
                </p>

                <p>
                  Podemos utilizar cualquier información personal de acuerdo con
                  nuestra política de privacidad.
                </p>
              </div>
            </div>

            <div
              id="block-satp-user-content"
              className="block block-system block-system-main-block"
            >
              <div className="content welcomeContent1">
                <h3 id="para">Acceso</h3>
              </div>
            </div>

            <div
              id="block-satp-user-content"
              className="block block-system block-system-main-block mt-3"
            >
              <div className="content">
                <p>
                  Su acceso y uso del Sitio está sujeto a estos términos y
                  condiciones. Acepta estar obligado por y cumplir con estos
                  términos y condiciones al navegar por el Sitio.
                </p>

                <p>
                  El Sitio puede contener enlaces a sitios web externos que no
                  están operados por nosotros ni por nuestros organismos
                  relacionados. Estos enlaces se proporcionan solo para su
                  conveniencia y:
                </p>
                <p>
                  <ol type="number">
                    <li>
                      No hacemos representaciones ni garantías, ni tenemos
                      ninguna responsabilidad o responsabilidad por esos sitios
                      web o su contenido.
                    </li>
                    <li>
                      {" "}
                      Estos enlaces no indican, expresa o implícitamente, que
                      aprobamos el sitio o los productos o servicios que se
                      proporcionan en esos sitios web.
                    </li>
                    <li>
                      {" "}
                      No respaldamos específicamente ninguna organización,
                      asociación o entidad mencionada en o vinculada al Sitio.
                    </li>
                  </ol>
                </p>
              </div>
            </div>

            <div
              id="block-satp-user-content"
              className="block block-system block-system-main-block"
            >
              <div className="content welcomeContent1">
                <h3 id="para">Cuenta y Detalles de Inicio de Sesión</h3>
              </div>
            </div>

            <div
              id="block-satp-user-content"
              className="block block-system block-system-main-block mt-3"
            >
              <div className="content">
                <p>
                  Los usuarios se registrarán para usar el Sitio a través del
                  sistema de registro automático. Los usuarios registrarán su
                  nombre y apellido, dirección de correo electrónico y
                  ciudad/país de residencia, y crearán un nombre de usuario y
                  una contraseña. El usuario tendrá acceso al sitio durante 5
                  meses a partir del momento del registro.
                </p>

                <p>
                  Usted acepta no acceder (o intentar acceder) al Sitio por
                  ningún medio que no sea a través del inicio de sesión
                  proporcionado. Su inicio de sesión consiste en el nombre de
                  usuario y la contraseña que registre. Acepta no compartir su
                  inicio de sesión con otros ni divulgar su inicio de sesión a
                  terceros no autorizados para ningún propósito. Acepta
                  notificar de inmediato cualquier uso no autorizado de su
                  inicio de sesión o cualquier otra violación de seguridad que
                  tenga conocimiento.
                </p>
              </div>
            </div>

            <div
              id="block-satp-user-content"
              className="block block-system block-system-main-block"
            >
              <div className="content welcomeContent1">
                <h3 id="para">Acciones Prohibidas</h3>
              </div>
            </div>

            <div
              id="block-satp-user-content"
              className="block block-system block-system-main-block mt-3"
            >
              <div className="content">
                <p>No puede:</p>

                <p>
                  <ol type="number">
                    <li>
                      Usar el Sitio de manera contraria a estos Términos,
                      nuestros estatutos, normas, políticas y procedimientos.
                    </li>
                    <li>
                      Usar el Sitio para actividades que violen leyes, infrinjan
                      derechos de terceros o sean contrarias a normas o códigos
                      relevantes, incluyendo, pero no limitado a, violaciones de
                      la Ley de Derechos de Autor de 1968 (Cth).
                    </li>
                    <li>
                      Excepto según lo permitido por la Ley de Derechos de Autor
                      de 1968 (Cth), otras leyes aplicables, o según lo
                      expresamente autorizado por nosotros o estos Términos, no
                      debe adaptar, reproducir, copiar, almacenar, transmitir,
                      imprimir, comunicar al público, distribuir, publicar,
                      crear trabajos derivados o usar ninguna parte del Sitio o
                      Programa para ningún propósito.
                    </li>
                    <li>
                      Usar el Sitio de una manera o forma, o publicar o
                      transmitir a o a través del Sitio, cualquier material que
                      interfiera con otros usuarios o nuestros otros clientes o
                      que difame, acose, amenace, intimide u ofenda a cualquier
                      persona o que impida a cualquier otra persona usar o
                      disfrutar del Sitio.
                    </li>
                    <li>
                      Hacer consultas o solicitudes fraudulentas o especulativas
                      a través del Sitio.
                    </li>
                    <li>
                      Usar los datos de otra persona sin su permiso o hacerse
                      pasar por otra persona al usar el Sitio.
                    </li>
                    <li>
                      Compartir su contraseña o detalles de inicio de sesión.
                    </li>
                    <li>
                      Publicar o transmitir cualquier material obsceno,
                      indecente, inflamatorio o pornográfico o cualquier otro
                      material que pueda dar lugar a procedimientos civiles o
                      penales.
                    </li>
                    <li>
                      Manipular o obstaculizar el funcionamiento del Sitio.
                    </li>
                    <li>
                      Transmitir intencionalmente virus, gusanos, defectos,
                      caballos de Troya o código malicioso similar al Sitio.
                    </li>
                    <li>
                      Usar cualquier robot, araña, aplicación de búsqueda y
                      recuperación de sitios u otro mecanismo para recuperar o
                      indexar cualquier parte del Sitio.
                    </li>
                    <li>
                      Decodificar, descompilar, desensamblar o realizar
                      ingeniería inversa de cualquier software en el Sitio, o de
                      cualquier forma utilizado o descargado del Sitio.
                    </li>
                    <li>
                      Modificar, adaptar o traducir cualquier parte del Sitio.
                    </li>
                    <li>
                      Usar cualquier software en el Sitio, o descargado del
                      Sitio, para crear un producto competidor.
                    </li>
                    <li>
                      Eliminar cualquier aviso de derechos de autor, marca
                      registrada u otros avisos de derechos de propiedad
                      contenidos en o en el Sitio.
                    </li>
                    <li>
                      Reformatear o enmarcar cualquier parte de las páginas web
                      que son parte del Sitio.
                    </li>
                    <li>
                      Crear cuentas mediante medios automáticos o bajo falsas o
                      fraudulentas pretensiones.
                    </li>
                    <li>
                      Usar el Sitio para violar la seguridad de cualquier
                      computadora u otra red o participar en conducta ilegal.
                    </li>
                    <li>
                      Tomar cualquier acción que imponga o que, en nuestra
                      opinión razonable, resulte en una carga irrazonable o
                      desproporcionadamente grande en nuestra infraestructura.
                    </li>
                    <li>
                      Usar el Sitio de manera diferente a la prevista en estos
                      términos y condiciones; o
                    </li>
                    <li>
                      Intentar cualquiera de los actos anteriores o permitir que
                      otra persona realice cualquiera de los actos anteriores.
                    </li>
                  </ol>
                </p>
              </div>
            </div>

            <div
              id="block-satp-user-content"
              className="block block-system block-system-main-block"
            >
              <div className="content welcomeContent1">
                <h3 id="para">Terminación</h3>
              </div>
            </div>

            <div
              id="block-satp-user-content"
              className="block block-system block-system-main-block mt-3"
            >
              <div className="content">
                <p>
                  Podemos suspender, terminar o limitar inmediatamente su acceso
                  y uso del Sitio si incumple estos Términos:
                </p>

                <p>
                  <ol type="number">
                    <li>La infracción no puede ser subsanada; o</li>
                    <li>
                      Usted no subsana la infracción dentro de los 10 días
                      posteriores a nuestra notificación de esa infracción; o
                    </li>
                    <li>
                      Si hay una emergencia o necesitamos realizar algún
                      mantenimiento en el Sitio.
                    </li>
                  </ol>
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-3"></div>
          <div className="fixedDiv col-md-12">
            <StaticFooter />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfUse;
