import React, { useState } from "react";
import { Button, InputGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import ReactTooltip from "react-tooltip";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { Helmet } from "react-helmet";
import { BASE_URL } from "../../../../env/Baseurl";
import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";
import Header from "../../../global/Header";
import Sidebar from "../../../global/Sidebar";
import Footer from "../../../global/Footer";
import {
  GetWhatWasLearned,
  GetWhatWasLearnedFlag,
} from "../../../../services/part4/what-was-learned";
import RefreshToken from "../../../../services/refresh-token";
import ErrorLogging from "../../../../services/error-logs";
import FooterOfPrint from "../../../global/FootorOfPrint";
import { useTranslation } from "react-i18next";

const Part4TwelfthPage = () => {
  const { t } = useTranslation(["partFourTwelfthPage", "actions"]);

  const [successMessage, setSuccessMessage] = useState("");
  const [postErrorMessage, setErrorMessage] = useState("");

  const userInfo = localStorage.getItem("user"); // Logged in user info
  const user = JSON.parse(userInfo);

  const [whatWasLearned, errorMessage] = GetWhatWasLearned(); // Get Learned Data from api
  const [whatWasLearnedFlag, errorMessageFlag] = GetWhatWasLearnedFlag();

  /*******   States *******/

  const [disabled, setDisabled] = useState(true);
  const [currentRadioValue, setCurrentRadioValue] = useState();
  // error modal
  const [showError, setShowError] = useState(false);
  const handle = () => setShowError(true);
  let close = () => setShowError(false);

  /*******   Helper Functions  *******/

  let navigate = useNavigate();

  const handleRadioChange = (e) => {
    setCurrentRadioValue(e.target.value);
    setDisabled(false);
  };

  const postSubmission = async () => {
    const userInfo = localStorage.getItem("user");
    const user = JSON.parse(userInfo);
    try {
      const params = JSON.stringify({
        select_counter: currentRadioValue,
      });
      const response = await axios.post(
        BASE_URL + `yes_no_counter_create_part4/${user.userInfo._id}`,
        params,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "content-type": "application/json",
            languageCode: user.userInfo.languageCode,
          },
        }
      );
      setSuccessMessage(response.data);
      if (currentRadioValue.length !== 0 && currentRadioValue === "yes") {
        navigate("/part_4/avoid", { replace: true });
      } else {
        navigate("/part_4/continue", { replace: true });
      }
    } catch (error) {
      if (error.response.status === 401) {
        RefreshToken(user.userInfo._id);
        postSubmission();
      } else {
        setErrorMessage("Please Part 4 create flag Api");
        ErrorLogging("HTTP POST /part4_flag_create/userId" + error);
        handle();
      }
    }
  };

  const nextPage = () => {
    navigate("/part_5/essay", { replace: true });
  };

  const previousPage = () => {
    navigate(`/part_4/behavioural_causal_thought_sample_answers`, {
      replace: true,
    });
  };

  //print function
  const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML;

    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").innerHTML = realData;
    document.getElementById("mainContainer").style.display = "none";
    // document.getElementById("printFooter").style.display = "block";
    window.print();
    document.getElementById("mainContainer").style.display = "block";
    document.getElementById("printAble").innerHTML = secondData;
    // if (dataSubmitted === true) {
    //     document.getElementById('main').innerHTML = realData;
    // }
    if (
      whatWasLearnedFlag !== undefined &&
      whatWasLearnedFlag.length !== 0 &&
      whatWasLearnedFlag.global_disable_status === false
    ) {
    } else {
      document.getElementById("main").innerHTML = realData;
    }
  };

  /*******   Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title>{t("partFourTwelfthPage:helmet")}</title>
        </Helmet>
        <div>
          {/* <Header /> */}
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-10 content-widthsidebar content-wrapper">
            <div className="card">
              <div className="card-body">
                <div id="main">
                  <div className="card-body">
                    <div className="title mb-3">
                      {t("partFourTwelfthPage:title")}
                    </div>
                    <p>
                      <span className="redText">
                        {t("partFourTwelfthPage:content.line1")}
                      </span>{" "}
                      {whatWasLearned?.length !== 0 && (
                        <>{whatWasLearned?.my_casual_thought_was}</>
                      )}
                    </p>
                    <p>
                      <span className="redText">
                        {t("partFourTwelfthPage:content.line2")}
                      </span>{" "}
                      {whatWasLearned?.length !== 0 && (
                        <>{whatWasLearned?.statement}</>
                      )}
                    </p>
                    <p className="redText">
                      {t("partFourTwelfthPage:content.line3")}
                    </p>
                    <ul>
                      {errorMessage ? (
                        <div className="container mt-5">
                          <div className="row justify-content-center">
                            <div
                              className="alert alert-danger text-center"
                              role="alert"
                            >
                              {errorMessage}
                            </div>
                          </div>
                        </div>
                      ) : whatWasLearned !== undefined &&
                        whatWasLearned?.length !== 0 ? (
                        <div>
                          {whatWasLearned?.pred_prob_data?.map(
                            (item, index) => {
                              const card = (
                                <li key={index}>{item.statement}</li>
                              );
                              return card;
                            }
                          )}
                        </div>
                      ) : (
                        <div className="loader-icon">
                          <TailSpin color="#4f5962" height={50} width={50} />
                        </div>
                      )}
                    </ul>
                    <p className="redText">
                      {t("partFourTwelfthPage:content.line4")}
                    </p>
                    <ul>
                      {errorMessage ? (
                        <div className="container mt-5">
                          <div className="row justify-content-center">
                            <div
                              className="alert alert-danger text-center"
                              role="alert"
                            >
                              {errorMessage}
                            </div>
                          </div>
                        </div>
                      ) : whatWasLearned !== undefined &&
                        whatWasLearned.length !== 0 ? (
                        <div>
                          {whatWasLearned?.This_is_what_happened?.map(
                            (item, index) => {
                              const card = (
                                <li key={index}>{item?.userresponce}</li>
                              );
                              return card;
                            }
                          )}
                        </div>
                      ) : (
                        <div className="loader-icon">
                          <TailSpin color="#4f5962" height={50} width={50} />
                        </div>
                      )}
                    </ul>
                    <p>
                      {t("partFourTwelfthPage:content.line5")}
                      <i>
                        {" "}
                        {whatWasLearned?.length !== 0 && (
                          <>{whatWasLearned?.the_task_i_was_compleated}</>
                        )}
                      </i>
                    </p>
                    <div className="radio-button"></div>
                    {errorMessageFlag ? (
                      <div className="container mt-5">
                        <div className="row justify-content-center">
                          <div
                            className="alert alert-danger text-center"
                            role="alert"
                          >
                            {errorMessageFlag}
                          </div>
                        </div>
                      </div>
                    ) : whatWasLearnedFlag !== undefined &&
                      whatWasLearnedFlag?.length !== 0 ? (
                      <div className="content-wrap whatLearned">
                        {whatWasLearnedFlag?.data.map((item, index) => {
                          const card = (
                            <div
                              className="left-content checkbx-sty"
                              key={index}
                            >
                              <InputGroup.Checkbox
                                className="radio-size"
                                defaultChecked={item.responce}
                                disabled={item.disable_status}
                                type="radio"
                                value={item.value}
                                name="radio-item-3"
                                selected={
                                  Boolean(currentRadioValue) &&
                                  currentRadioValue === item.value
                                }
                                onChange={handleRadioChange}
                              />

                              {item.statement}
                            </div>
                          );
                          return card;
                        })}
                      </div>
                    ) : (
                      <div className="loader-icon">
                        <TailSpin color="#4f5962" height={50} width={50} />
                      </div>
                    )}
                  </div>
                  {/* <div>
                                        <FooterOfPrint/>
                                    </div> */}
                </div>
                <div className="row">
                  <div className="previous1 col-6">
                    <Button
                      className="user-reponse-submit btn btn-primary link-sty"
                      onClick={previousPage}
                    >
                      {t("actions:previous")}
                    </Button>
                  </div>
                  <div className="next col-6">
                    {whatWasLearnedFlag !== undefined &&
                    whatWasLearnedFlag?.length !== 0 &&
                    whatWasLearnedFlag?.global_disable_status === false ? (
                      <>
                        <span
                          data-tip={
                            disabled === true
                              ? t("partFourTwelfthPage:toolTipText")
                              : ""
                          }
                          data-for="toolTip"
                        >
                          <Button
                            className="user-reponse-submit btn btn-primary link-sty"
                            onClick={postSubmission}
                            disabled={disabled}
                          >
                            {t("actions:continue")}
                          </Button>
                        </span>
                        <ReactTooltip id="toolTip" />
                      </>
                    ) : (
                      <>
                        <Button
                          className="user-reponse-submit btn btn-primary link-sty"
                          onClick={nextPage}
                        >
                          {t("actions:continue")}
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* error message modal */}

          <Modal show={showError}>
            <Modal.Body>{postErrorMessage}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={close}>
                {t("actions:close")}
              </Button>
            </Modal.Footer>
          </Modal>
          <div className="col-md-12 dashboard-footer">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Part4TwelfthPage;
