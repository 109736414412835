import React, { useState, useEffect, useRef } from "react";
import ReactAudioPlayer from "react-audio-player";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import axios from "axios";

import { BASE_URL } from "../../env/Baseurl";
import { DOMAIN_NAME } from "../../env/Baseurl";
import ErrorLogging from "../../services/error-logs";
import RefreshToken from "../../services/refresh-token";
import getAudio from "../../actions/audio";
import { useTranslation } from "react-i18next";
import eBook from "../../Ebook/eBook-iGlebe-July2021.pdf";
import eBookEs from "../../Ebook/iGlebe_eBook_Castilian_Spanish.pdf";

const HoverText = () => {
  const { t } = useTranslation(["footer"]);
  return <div className="hover-txt">{t("hoverText")}</div>;
};

const Footer = () => {
  const audioplayerRef = useRef(null);
  const loc = useLocation();
  const { t } = useTranslation(["footer"]);

  /*******   States *******/
  const [errorApiMessage, setErrorMessage] = useState("");
  const [isHovering, setIsHovering] = useState(false);
  const [progressBarData, setProgressBarData] = useState({
    num: 0,
    progress: 0,
  });

  /*******   Redux *******/
  const { audio } = useSelector((state) => state.audio);
  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();

  /*******   Localstorage *******/
  const preTreatmentProgress = localStorage.getItem("preTreatmentProgress");
  const moduleParts = localStorage.getItem("moduleParts");
  const moduleName = localStorage.getItem("moduleName");
  const moduleProgress = localStorage.getItem("moduleProgress");

  // Get the user's language from local storage
  const userInfo = JSON.parse(localStorage.getItem("user"));
  const languageCode = userInfo?.userInfo?.languageCode;

  /*******   Helper Constants *******/

  /*******   Helper Functions *******/
  const location = useLocation();

  const changePasswordColor = () => ({
    width: `${progressBarData.num}%`,
  });

  const moduleProgressBar = () => ({
    width: `${progressBarData.progress}%`,
  });

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const userVisitedPage = async () => {
    const userInfo = localStorage.getItem("user");

    const user = JSON.parse(userInfo);

    try {
      const params = JSON.stringify({
        lastPage_visited: location?.pathname,
      });
      const response = await axios.post(
        BASE_URL + `page_partUpdate/${user?.userInfo._id}`,
        params,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "content-type": "application/json",
            languageCode: user?.userInfo.languageCode,
          },
        }
      );

      localStorage.setItem("last_visited_url", response.data.page_url);
    } catch (error) {
      if (error?.response?.status === 401) {
        RefreshToken(user?.userInfo._id);
        userVisitedPage();
      } else {
        setErrorMessage("Please check Page PartUpdate Api");
        ErrorLogging("HTTP POST /page_partUpdate/userId" + error);
      }
    }
  };

  /*******   Hooks  *******/
  useEffect(() => {
    dispatch(getAudio(location?.pathname))
      .then(() => {})
      .catch(() => {});
  }, [message]);

  useEffect(() => {
    userVisitedPage();
  }, []);

  useEffect(() => {
    if (audioplayerRef?.current) {
      // audioplayerRef?.current?.audioEl?.current?.play();
    }
  }, [audio?.audioData?.audio_path]);
  // download copy

  const downloadCopy = (e) => {
    e.preventDefault();
    if (loc.pathname === "/part_7/complete") {
      if (languageCode == "en") {
        window.open(eBook, "_blank");
      } else {
        window.open(eBookEs, "_blank");
      }
    } else return;
  };

  /*******   Render  *******/

  return (
    <div>
      <div className="row footer">
        {audio !== undefined && audio.length !== 0 ? (
          <>
            <div className="col-md-4 ">
              <div className="ft-headstext">{t("progressLabel")}</div>
              <div className="row subtext-blocksec">
                <div className="col-md-6">
                  <span> {audio.progressbarData.part} </span>
                  <div className="progress" style={{ height: "3px" }}>
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{
                        width: (audio.progressbarData.module * 100) / 7 + "%",
                      }}
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
                <div className="col-md-6">
                  <span>{audio.progressbarData.page_type}</span>
                  <div className="progress" style={{ height: "3px" }}>
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{
                        width:
                          (audio.progressbarData.page_no * 100) /
                            audio.progressbarData.total_page_no +
                          "%",
                      }}
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              {audio?.audioData?.status === "active" && (
                <>
                  <div className="audio-title">{t("audioLabel")}</div>
                  <div className="audio-player">
                    <ReactAudioPlayer
                      controls
                      autoPlay={true}
                      src={audio.audioData.audio_path}
                      type="audio/mpeg"
                      ref={audioplayerRef}
                    />
                  </div>
                </>
              )}
            </div>
          </>
        ) : (
          <div className="col-md-8 loader-icon">
            <TailSpin color="#4f5962" height={50} width={50} />
          </div>
        )}

        <div className="col-md-4">
          <div className="ft needHelp">{t("helpLabel")}</div>
          <div className="row">
            <div className="col-md-7 ftboxlinks-blue">
              <Link to="/feeling_sad">
                {" "}
                <img
                  className="fticons"
                  src={`${DOMAIN_NAME}images/${languageCode}/footer/Iconawesomesadtear.png`}
                  alt="feeling-sad"
                />{" "}
                {t("feelingSad")}
              </Link>
            </div>
            <div
              className="col-md-5 ftboxlinks-red"
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
            >
              <Link to="#" onClick={downloadCopy}>
                {" "}
                <img
                  className="fticons"
                  src={`${DOMAIN_NAME}images/${languageCode}/footer/Iconawesomebookopen.png`}
                  alt="ebook"
                />{" "}
                {t("freeEbook")}
              </Link>
            </div>
            {isHovering && <HoverText />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
